import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="TIF_Logo_Socials.png"
								alt="The Insights Family"
								className="work-image"
							/>
							<div className="work-title">The Insights Family</div>
							<div className="work-subtitle">
								Data Scientist - Manchester, UK
							</div>
							<div className="work-duration"> Mar 2021 - Present</div>
						</div>

						<div className="work">
							<img
								src="University_of_Newcastle_Coat_of_Arms.svg"
								alt="Research Assistant"
								className="work-image"
							/>
							<div className="work-title">Newcastle University</div>
							<div className="work-subtitle">
							Research Assistant - Newcastle, UK
							</div>
							<div className="work-duration">Feb 2020 - Sept 2020</div>
						</div>



						
						<div className="work">
							<img
								src="Dell_logo_2016.svg.png"
								alt="Data Analyst"
								className="work-image"
							/>
							<div className="work-title">Dell</div>
							<div className="work-subtitle">
							Data Analyst - Gurgaon, India
							</div>
							<div className="work-duration">Nov 2017 - Oct 2018</div>
						</div>

						
						<div className="work">
							<img
								src="globallogic.jpg"
								alt="Data Analyst"
								className="work-image"
							/>
							<div className="work-title">Global Logic</div>
							<div className="work-subtitle">
							Data Analyst - Gurgaon, India
							</div>
							<div className="work-duration">Aug 2016 - Nov 2017</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
