const INFO = {
	main: {
		title: "Disha Data Scientist",
		name: "Disha Rajpal",
		email: "disharajpal19@gmail.com",
		logo: "../disha_profile.jpeg",
	},

	socials: {
		
		github: "https://github.com/Disha819",
		linkedin: "https://www.linkedin.com/in/disha-rajpal/",
		
	},

	homepage: {
		title: "Data Scientst and Researcher",
		description:
			"I'm a passionate Data Scientist with a strong background in turning data into actionable insights. I specialize in machine learning, predictive modelling, and data-driven decision-making using experimentation testing such as hypothesis testing. With a commitment to solving complex problems.",
	},

	about: {
		title: "I’m Disha I live in Manchester UK, where I work with data",
		description:
			"My experience spans across various industries, from finance to healthcare, where I've contributed to projects that have improved efficiency, reduced costs, and enabled data-driven strategies. I'm proficient in Python, R, and various data analysis tools, and I have a knack for creating data-driven visualizations that tell compelling stories.vIf you're looking for a data-driven problem solver who thrives on the challenge of extracting insights from complex datasets and turning them into business value, let's connect!I'm a passionate Data Scientist with a strong background in turning data into actionable insights. I specialize in machine learning, predictive modelling, and data-driven decision-making using experimentation testing such as hypothesis testing. With a commitment to solving complex problems. My experience spans across various industries, from finance to healthcare, where I've contributed to projects that have improved efficiency, reduced costs, and enabled data-driven strategies. I'm proficient in Python, R, and various data analysis tools, and I have a knack for creating data-driven visualizations that tell compelling stories. If you're looking for a data-driven problem solver who thrives on the challenge of extracting insights from complex datasets and turning them into business value, let's connect! Top skillsTop skills Python (Programming Language) • SQL • Machine Learning • Cloud Computing (AWS, GCP) • Hypothesis Testing",
	},
	/*
	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},
	*/
	projects: [
		{
			title: "Anomaly Detection",
			description:
				"A smart building is a structure that can provide users with an efficient, comfortable and convenient humanized building environment. Unfortunately, due to these benefits they have also become the next focus area for hackers with malicious intent. In this group project we wrote a program based on unsupervised algorithms to detect abnormal data gathered by smart buildings, specifically the Urban Sciences Building in Newcastle University. This program simulates what one form of attack could achieve, changing key data gathered 24 hours a day by the sensors encapsulated in the USB.",
				logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/Disha819/Anomaly-Detection",
		},

		{
			title: "Selecting different dataset comparison techniques based on automatic data set analysis",
			description:
				"Visualization is one of the essential components of research presentation and communication due to its ability to convert large datasets into compelling graphics, visualize ation techniques applied during data analysis with exploration analysis, statistical modelling validation up to results.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		
	],
};

export default INFO;
